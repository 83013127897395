<template>
  <div>
    <a-form
      id="components-form-demo-normal-login"
      :form="form"
      class="login-form"
      @submit="handleSubmit"
    >
      <a-form-item>
        <a-input
        v-decorator="['title', {
              rules: [
                { required: true, message: 'Please input QuestionName!' },
              ],
            },]"
          size="large"
          placeholder="Question Name*"
        >
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-textarea
        rows=5
        v-decorator="['name', {
              rules: [
                { required: true, message: 'Please input QuestionText!' },
              ],
            },]"
          size="large"
          placeholder="Question Text*"
        >
        </a-textarea>
      </a-form-item>
      <a-form-item>
        <a-select :disabled="whileSaving" v-decorator="['answer', {
              rules: [
                { required: true, message: 'Please input Safe Answer!' },
              ],
            },]" default-value="yes" size="large" placeholder="Safe Answer*">
          <a-select-option value="yes">
            Yes
          </a-select-option>
          <a-select-option value="no">
            No
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-checkbox v-decorator="['completeSurvey']">
          Complete Survey on Safe Answer
        </a-checkbox>
      </a-form-item>
      <br />
      <a-form-item>
        <a-button
          size="large"
          type="primary"
          html-type="submit"
          class="login-form-button"
          style="width: 100%; background: #5168ea; border-color: #5168ea"
        >
          <a-icon type="lock" style="color: white" />
          <span>Save Question</span>
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import { QuestionApi } from "../../services";

export default {
  components: {

  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "normal_login" });
  },
  methods: {
    async handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (!err) {
          try {
            await QuestionApi.create(values);
            this.form.resetFields();
            this.$message.success(
              "Question Added Successfully"
            );
            this.$emit('success', true);
          } catch (err) {
            if (err.response.data && err.response.data.message) {
              this.$message.error(err.response.data.message);
            } else {
              this.$error.message.error(
                "Something went wrong. Please contact administrator."
              );
            }
          }
          console.log("Received values of form: ", values);
        }
      });
    },
  },
};
</script>
<style scoped>

a {
  color: #5168ea !important;
}
.auth-icon {
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 0.25);
  vertical-align: middle;
  margin-left: -10px;
  display: inline-block;
  margin-top: -8px;
}
</style>