





































































































import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
const QuestionnaireModule = namespace("QuestionnaireModule");
import { Question } from "../../store/modules/questionnaire/types";
import Icon from "../../components/Icon.vue";
import GlobalQuestionnaireAdd from './GlobalQuestionnaireAdd.vue';
import { sortStringAttributes } from '../../services';
import HtmlPreview from 'vue-html-viewer';

@Component({
  components: {
    Icon,
    GlobalQuestionnaireAdd,
    HtmlPreview
  }
})
export default class QuestionList extends Vue {
  @QuestionnaireModule.Getter
  public questions: Question[];
  public searchTerm = "";
  visible = false;
  public columns = [
    {
      title: "Name",
      dataIndex: "title",
      sorter: (a, b) => sortStringAttributes(a.title, b.title),
    },
    {
      title: "Question",
      slots: { title: "name" },
      scopedSlots: { customRender: 'name' },
      sorter: (a, b) => sortStringAttributes(a.name, b.name),
    },
    {
      title: "Safe Answer",
      dataIndex: "answer",
      sorter: (a, b) => sortStringAttributes(a.answer, b.answer),
      slots: { title: "safeAnswer" },
      scopedSlots: { customRender: 'safeAnswer' },
    },
    {
      title: "Complete Survey",
      dataIndex: "completeSurvey",
      sorter: (a, b) => sortStringAttributes(a.completeSurvey, b.completeSurvey),
      slots: { title: "completeSurvey" },
      scopedSlots: { customRender: 'completeSurvey' },
    },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   sorter: (a, b) => sortStringAttributes(a.status, b.status),
    // },
    {
      title: "Created At",
      dataIndex: "createdAt",
      sorter: (a, b) => sortStringAttributes(a.createdAt, b.createdAt),
      slots: { title: "createdAt" },
      key: 'createdAt',
      scopedSlots: { customRender: 'createdAt' },
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      sorter: (a, b) => sortStringAttributes(a.updatedAt, b.updatedAt),
      slots: { title: "updatedAt" },
      key: 'updatedAt',
      scopedSlots: { customRender: 'updatedAt' },
    }
  ];
  
  @QuestionnaireModule.Action
  public load!: () => Promise<Question[]>;

  @QuestionnaireModule.Action
  public loadCDCQuestions!: () => Promise<void>;

  @QuestionnaireModule.Action
  public search!: (term:string) => Promise<Question[]>;

  async mounted() {
    await this.load();
  }

  navigate(to) {
    this.$router.push(to);
  }

  onSearch(term) {
    this.searchTerm = term;
    this.search(term);
  }

  questionAdded() {
    this.visible = false;
    this.load();
  }

  async loadDefault() {
    try {
      await this.loadCDCQuestions();
      this.$message.success("CDC Qusetions added successfully");
      this.load();
    } catch(err) {
      this.$message.success("CDC Qusetions failed add");
    }
  }

  customRow(record) {
    return {
      on: {
        click: (event) => {
          this.$router.push(`/survey/${record.surveyId}/configure`);
        },
      },
    };
  }
}
