var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['title', {
            rules: [
              { required: true, message: 'Please input QuestionName!' } ],
          } ]),expression:"['title', {\n            rules: [\n              { required: true, message: 'Please input QuestionName!' },\n            ],\n          },]"}],attrs:{"size":"large","placeholder":"Question Name*"}})],1),_c('a-form-item',[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name', {
            rules: [
              { required: true, message: 'Please input QuestionText!' } ],
          } ]),expression:"['name', {\n            rules: [\n              { required: true, message: 'Please input QuestionText!' },\n            ],\n          },]"}],attrs:{"rows":"5","size":"large","placeholder":"Question Text*"}})],1),_c('a-form-item',[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['answer', {
            rules: [
              { required: true, message: 'Please input Safe Answer!' } ],
          } ]),expression:"['answer', {\n            rules: [\n              { required: true, message: 'Please input Safe Answer!' },\n            ],\n          },]"}],attrs:{"disabled":_vm.whileSaving,"default-value":"yes","size":"large","placeholder":"Safe Answer*"}},[_c('a-select-option',{attrs:{"value":"yes"}},[_vm._v(" Yes ")]),_c('a-select-option',{attrs:{"value":"no"}},[_vm._v(" No ")])],1)],1),_c('a-form-item',[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:(['completeSurvey']),expression:"['completeSurvey']"}]},[_vm._v(" Complete Survey on Safe Answer ")])],1),_c('br'),_c('a-form-item',[_c('a-button',{staticClass:"login-form-button",staticStyle:{"width":"100%","background":"#5168ea","border-color":"#5168ea"},attrs:{"size":"large","type":"primary","html-type":"submit"}},[_c('a-icon',{staticStyle:{"color":"white"},attrs:{"type":"lock"}}),_c('span',[_vm._v("Save Question")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }